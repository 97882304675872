import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { getUserByTel } from "./service/loginService";
import "../node_modules/normalize.css";
import * as echarts from "echarts";
import VueAMap from "vue-amap";
Vue.use(VueAMap);
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
VueAMap.initAMapApiLoader({
  key: "f130b8f9217a257d1a825e072af59bbb",
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor"
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: "1.4.4",
  uiVersion: "1.0.11"
});
const token = localStorage.getItem("token");
const tel = localStorage.getItem("tel");
if (token) {
  // 获取用户信息
  store.commit("setToken", token);
  getUserByTel(tel).then(res => {
    store.commit("setUser", res);
  });
}
router.beforeEach((to, from, next) => {
  if (to.name === "Register") {
    next();
  } else if (to.name === "Resetpwd") {
    next();
  } else if (to.name !== "Login" && !store.state.token) {
    next({
      name: "Login"
    });
  } else {
    next();
  }
});
