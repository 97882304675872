import axios from "axios";
import router from '../router/index'
import {
  Toast
} from 'vant'
let token = ""
export const setToken = (value) => {
  token = value
}

const server = axios.create({
  baseURL: "http://110.87.103.58:5389/",
  timeout: 50000
});

// 添加一个请求拦截器
server.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: token || ''
      }
    }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// 添加一个响应拦截器
server.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    if (response.data.code === 401) {
      router.push('/login')
      Toast('登录状态过期，请重新登录')
    }
    return response.data;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default server;
