import Vue from "vue";
import Vuex from "vuex";
import {
  setToken
} from "@/service/axios.config";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    LocationMessage: null,
    user: null,
    tel: null,
    token: null,
    landIds: [],
    cropIds: [],
    workDate: "",
    show: false,
    lng: "",
    lat: "",
    farmlandName: "",
    irrigation: "",
    irrigation_id: "",
    farmlandArea: "",
    contactPerson: "",
    contactNumber: "",
    da_crops_id: "",
    fileList: [],
    // 添加圈舍部分
    name: "",
    area: ""
  },
  mutations: {
    setLocation(state, payload) {
      state.LocationMessage = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setTel(state, payload) {
      state.tel = payload;
      localStorage.setItem("tel", payload);
    },
    setToken(state, payload) {
      state.token = payload;
      localStorage.setItem("token", payload);
      setToken(payload);
    },
    exit(state) {
      state.token = null;
      localStorage.removeItem("token");
      setToken(``);
    },
    setLandIds(state, payload) {
      state.landIds = payload;
    },
    setWorkDate(state, payload) {
      state.workDate = payload;
    },
    setCropIds(state, paylog) {
      state.cropIds = paylog;
    },
    setShow(state, paylog) {
      state.show = paylog;
    },
    setLng(state, paylaod) {
      state.lng = paylaod;
    },
    setLat(state, paylaod) {
      state.lat = paylaod;
    },
    setfarmlandName(state, paylaod) {
      state.farmlandName = paylaod;
    },
    setirrigation(state, paylaod) {
      state.irrigation = paylaod;
    },
    setirrigation_id(state, paylaod) {
      state.irrigation_id = paylaod
    },
    setfarmlandArea(state, paylaod) {
      state.farmlandArea = paylaod
    },
    setcontactPerson(state, paylaod) {
      state.contactPerson = paylaod
    },
    setcontactNumber(state, paylaod) {
      state.contactNumber = paylaod
    },
    setfileList(state, paylaod) {
      state.fileList = paylaod
    },
    setda_crops_id(state, paylaod) {
      state.da_crops_id = paylaod
    },
    setname(state, paylaod) {
      state.name = paylaod
    }
  },
  actions: {},
  modules: {}
});
