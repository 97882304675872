import Vue from "vue";
import VueRouter from "vue-router";
// import { component } from "vue/types/umd";
Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Home",
    component: () => import("@/views/home/Home.vue")
  },
  {
    path: "/home/Warning",
    name: "Warning",
    component: () => import("@/views/home/Warning")
  },
  {
    path: "/home/measureOutput",
    name: "LeasureOutput",
    component: () => import("@/views/home/MeasuringOutput")
  },
  {
    path: "/home/agrometeorologicalForecast",
    name: "AgrometeorologicalForecast",
    component: () => import("@/views/home/AgrometeorologicalForecast")
  },
  // 服务栏目
  {
    path: "/server",
    name: "Server",
    component: () => import("@/views/server/serverIndex.vue")
  },
  // 地图 map
  {
    path: "/fieldMap",
    name: "FieldMap",
    component: () => import("@/views/server/fieldMap.vue")
  },
  {
    path: "/feedingRecord",
    name: "FeedingRecord",
    props: true,
    component: () => import("@/views/server/feedingRecord.vue")
  },
  // 田地 新增作物
  {
    path: "/addCrop/:fieldId",
    name: "AddCrop",
    props: true,
    component: () => import("@/views/server/addCrop.vue")
  },
  // 作物 改种
  {
    path: "/addCrop/:fieldId/:cropId",
    name: "AddCrop",
    props: true,
    component: () => import("@/views/server/addCrop.vue")
  },
  // 田地 详情
  {
    path: "/server/fieldDetail/:id",
    name: "FieldDetail",
    props: true,
    component: () => import("@/views/server/fieldDetail.vue")
  },
  // 作物 详情
  {
    path: "/server/livestockDetails/:id",
    name: "livestockDetails",
    props: true,
    component: () => import("@/views/server/livestockDetails.vue")
  },
  // 牲畜详情
  {
    path: "/server/cropDetail/:id",
    name: "cropDetail",
    props: true,
    component: () => import("@/views/server/cropDetail.vue")
  },
  // 圈舍详情
  {
    path: "/server/EnclosureDetails/:id",
    name: "EnclosureDetails",
    props: true,
    component: () => import("@/views/server/EnclosureDetails.vue")
  },
  {
    path: "/server/soilList/:id",
    name: "SoilList",
    props: true,
    component: () => import("@/views/server/soilList.vue")
  },
  // 添加养殖
  {
    path: "/server/AddBreeding/:id",
    name: "AddBreeding",
    props: true,
    component: () => import("@/views/server/AddBreeding.vue")
  },
  {
    path: "/mine",
    name: "Mine",
    component: () => import("@/views/mine/MineIndex.vue")
  },
  {
    path: "/mine/settings",
    name: "Settings",
    component: () => import("@/views/mine/Settings.vue")
  },
  {
    path: "/mine/harvestRecords",
    name: "HarvestRecords",
    component: () => import("@/views/mine/HarvestRecords.vue")
  },
  {
    path: "/mine/Enclosure",
    name: "Enclosure",
    component: () => import("@/views/mine/Enclosure.vue")
  },
  {
    path: "/mine/archives",
    name: "Archives",
    component: () => import("@/views/mine/archives.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/LoginIndex.vue")
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/login/Register.vue")
  },
  {
    path: "/resetpwd",
    name: "Resetpwd",
    component: () => import("@/views/login/ResetPassword.vue")
  },
  {
    path: "/home/recordHarvest",
    name: "RecordHarvest",
    component: () => import("@/views/home/RecordHarvest")
  },
  {
    path: "/newdetail/:id",
    name: "Newdetail",
    component: () => import("@/views/home/Newldetail.vue")
  },
  {
    path: "/archivesDetails/:id",
    name: "ArchivesDetails",
    component: () => import("@/views/Farmlandmanagement/archivesDetails.vue")
  },
  {
    path: "/plantingDetails/:id",
    name: "PlantingDetails",
    component: () => import("@/views/Farmlandmanagement/plantingDetails.vue")
  },
  {
    path: "/addEnclosure",
    name: "AddEnclosure",
    component: () => import("@/views/Farmlandmanagement/addEnclosure.vue")
  },
  {
    path: "/addArchives",
    name: "AddArchives",
    meta: {
      alive: true
    },
    component: () => import("@/views/Farmlandmanagement/addArchives.vue")
  },
  {
    path: "/addfarmland",
    name: "AddFarmland",
    component: () => import("@/views/Farmlandmanagement/Addfarmland.vue")
  },
  {
    path: "/farmlandlist",
    name: "FarmlandList",
    component: () => import("@/views/Farmlandmanagement/FarmlandList.vue")
  },
  {
    path: "/editfarmland/:id",
    name: "editFarmland",
    component: () => import("@/views/Farmlandmanagement/editFarmland.vue")
  },
  {
    path: "/EditArchives/:id",
    name: "EditArchives",
    component: () => import("@/views/Farmlandmanagement/EditArchives.vue")
  },
  {
    path: "/editPenland/:id",
    name: "editPenland",
    component: () => import("@/views/Farmlandmanagement/editPenland.vue")
  },
  {
    path: "/expert",
    name: "expert",
    component: () => import("@/views/Expert/expert.vue")
  },
  {
    path: "/farmworkType",
    name: "farmworkType",
    component: () => import("@/views/farmworkType/farmworkType.vue")
  },
  {
    path: "/farmworktypedetail",
    name: "farmworktypeDetail",
    component: () => import("@/views/farmworkType/farmworktypeDetail.vue")
  },
  {
    path: "/message",
    name: "message",
    component: () => import("@/views/Mymessage/message.vue")
  },
  {
    path: "/messagedetail/:id",
    name: "messageDetail",
    component: () => import("@/views/Mymessage/messageDetail.vue")
  },
  {
    path: "/planthistory/:id",
    name: "PlantHistory",
    component: () => import("@/views/Farmlandmanagement/PlantHistory.vue")
  },
  {
    path: "/historydetail/:id",
    name: "HistoryDetail",
    component: () => import("@/views/Farmlandmanagement/HistoryDetail.vue")
  },
  {
    path: "/publiccrops",
    name: "PublicCrops",
    component: () => import("@/views/farmworkType/PublicCrops.vue")
  },
  {
    path: "/mymap",
    name: "Mymap",
    component: () => import("@/views/Farmlandmanagement/Mymap.vue")
  },
  {
    path: "/addmap",
    name: "addMap",
    component: () => import("@/views/Farmlandmanagement/addMap.vue")
  },
  {
    path: "/archivesMap",
    name: "ArchivesMap",
    component: () => import("@/views/Farmlandmanagement/archivesMap.vue")
  },
  {
    path: "/archivesMymap",
    name: "ArchivesMymap",
    component: () => import("@/views/Farmlandmanagement/archivesMymap.vue")
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
});

export default router;
