import server from "./axios.config.js";
import qs from 'qs'

export const login = function (username, password) {
  return server({
    method: "post",
    baseURL: "http://110.87.103.59:18086/",
    url: `oauth2/oauth/token`,
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify({
      grant_type: 'password',
      client_id: 'client-app',
      client_secret: '123456',
      username: username,
      password: password
    })
  });
};

export const sendSMS = function (mobile) {
  return server({
    method: "get",
    baseURL: "http://110.87.103.59:18086/",
    // url: `oauth2/captcha/validata/smsCode`,
    url: `oauth2/captcha/validata/XNsmsCode`,
    params: {
      businessType: "YHZC",
      mobile: mobile
    }
  });
};

export const regist = function (data) {
  return server({
    method: "post",
    baseURL: "http://110.87.103.59:18086/",
    url: `api-user/user/registerUser`,
    data: data
  });
};

export const getUser = function () {
  return server({
    method: "get",
    url: `http://110.87.103.59:18086/user-server/user/getLoginUser`
  });
};

export const getUserByTel = function (mobile) {
  return server({
    method: "get",
    url: `http://110.87.103.59:18086/api-user/user/findByMobile`,
    params: {
      mobile: mobile
    }
  });
};

// 发送验证码并判断手机是否为系统用户
export const ifSmsCode = function (mobile) {
  return server({
    method: "get",
    // url: `http://110.87.103.59:18086/oauth2/captcha/validata/ifSmsCode`,
    url: `http://110.87.103.59:18086/oauth2/captcha/validata/XNsmsCode`,
    params: {
      // ifSysUser: true,
      businessType: "ZHMM",
      mobile: mobile
    }
  });
};

// 验证码修改密码
export const updateSysUserPasswordSms = function (data) {
  return server({
    method: "post",
    url: `http://110.87.103.59:18086/api-user/user/updateSysUserPasswordSms`,
    data: data
  });
};
