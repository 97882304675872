<template>
  <div id="app">
    <!-- <transition name="fade"> -->
      <!-- <router-view /> -->
            <keep-alive>
        <router-view class="router-view" v-if="$route.meta.alive" key="$route.meta.alive"></router-view>
      </keep-alive>
    <!-- </transition> -->
      <router-view class="router-view" v-if="!$route.meta.alive" key="!$route.meta.alive"></router-view>
  </div>
</template>
<script>
import { getLocation } from "@/service/locationService.js";
export default {
  components: {},
  watch: {
    "$store.state.token": function (newValue) {
      const store = this.$store;
      getLocation().then(
        (res) => {
          store.commit("setLocation", res.data);
        },
        (err) => {
          console.log("定位失败", err);
          // 定位失败
        }
      );
    }
  },
  created() {
    // const store = this.$store;
    // getLocation().then(
    //   (res) => {
    //     store.commit("setLocation", res.data);
    //   },
    //   (err) => {
    //     console.log("定位失败", err);
    //     // 定位失败
    //   }
    // );
  }
};
</script>
<style>
</style>
